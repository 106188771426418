/***************************************************
  Imports
***************************************************/

import React from "react"

import List from "../../components/List"
import PageTypeE from "../../templates/PageTypeE"

import TitleImg from "../../images/D365-advanced-supply-chain-management.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Advanced Supply Chain Management"
      description="Reducing inventory carrying cost and increasing margins can cause a greater effect on the bottom line than increased sales."
      linkSubPageGroupID="D365Group"
      titleImg={TitleImg}
      infoList={[
        {
          title: "Advanced Forecasting & Procurement",
          text: (
            <p>
              As a distributor or manufacturer your largest and most costly
              asset is inventory. Reducing inventory carrying cost and
              increasing margins can cause a greater effect on the bottom line
              than increased sales. By doing both, you will significantly
              increase profitability.
              <h5 style={{ marginTop: "2rem" }}>
                For the Distributor or Manufacturer
              </h5>
              <List
                className="dark"
                items={[
                  {
                    text: `Formula Based Forecasting - Best Fit Formula Approach.`,
                  },
                  {
                    text: `Use Collaborative forecast to fine tune your overall forecast.`,
                  },
                  {
                    text: `Use Vendor forecast to help your vendors anticipate your future needs.`,
                  },
                  {
                    text: `Roll-Up usage history and demand based on replenishment path to get a view of your entire supply chain.`,
                  },
                  {
                    text: `Identify surplus inventory and re-deploy to reduce total inventory.`,
                  },
                  {
                    text: `Use the unique Lead Time Horizon replenishment method for long lead times typical when importing products.`,
                  },
                  {
                    text: `Use the suggested order to review products by vendor, including the calculation details of the recommendation.`,
                  },
                ]}
              />
              <h5>Specifically for the Distributor</h5>
              <List
                className="dark"
                items={[
                  {
                    text: `Automated Calculation of Vendor Lead Times to the Warehouse, Vendor, and Item levels.`,
                  },
                  {
                    text: `Branch replenishment, including consigned inventory replenishment.`,
                  },
                  {
                    text: `Order Point, Line Point, and EOQ calculations for short to medium lead times.`,
                  },
                  {
                    text: `Lead Time Horizon calculations for longer lead times.`,
                  },
                  {
                    text: `Non-Stock and Special Order item processing.`,
                  },
                  {
                    text: `Full support for Business Central Kit/BOM Processing.`,
                  },
                ]}
              />
              <h5>Specifically for the Manufacturer</h5>
              <List
                className="dark"
                items={[
                  {
                    text: `Use AFP Production to forecast finished goods and create simulated production orders for the planning horizon (typically several months).`,
                  },
                  {
                    text: `Component demand is considered by the suggested order when purchasing components for production.`,
                  },
                  {
                    text: `Can be used in conjunctions with Business Central Production.`,
                  },
                ]}
              />
            </p>
          ),
        },
        {
          title: "Electronic Data Interchange (EDI)",
          text: `The EDI module has been designed as a set of tools that provide the user with a maximum amount of flexibility when dealing with their trading partners. These tools can be used to map any Dynamics Business Central data element to an ANSI X12 standard. Once the mapping is in place, the Transaction Management tools allow the user to easily manage the day-to-day process of conducting electronic commerce.<br/><br/>Business systems are as diverse and varied as their names and origins indicate. The EDI ANSI X12 standard is an attempt to provide a common intermediate format in which to conduct electronic commerce between trading partners. The interpretation and use of the data elements within these standards is left up to the EDI standards committee in each industry and further modified by the individual trading partners. For example the electronics industry has a different set of requirements than does the retail distribution industry. This is further complicated by the requirements of each trading partner's business system.<br/><br/>Historically software providers have provided a hard coded custom solution to EDI which in turn is rigid and expensive to maintain and modify. A more modern approach is to provide this same rigid interface and use an EDI software vendor's logical mapping tool to complete the link. The problem with this solution is that the EDI mapping tool can only map and pass along data that is provided to it through the traditional rigid interface.<br/><br/>Our approach to EDI is to provide a single mapping tool that maps any Dynamics Business Central data element to any EDI data element without the requirement for programming.`,
        },
        {
          title: "E-Ship",
          text: (
            <p>
              Dynamics Business Central E-Ship integrates the entire packing and
              shipping function within the Dynamics Business Central database.
              Until now Dynamics Business Central customers have interfaced with
              external shipping systems. These external systems, while doing a
              good job of rating and shipping packages, do not have knowledge of
              the items being shipped. Because E-Ship is held within Dynamics
              Business Central it can easily:
              <List
                className="dark"
                items={[
                  {
                    text: `Shop rates at Sales Order entry time thus allowing you to quote the shipping cost up front.`,
                  },
                  {
                    text: `Capture package level detail using our unique Scan Only packing operation.`,
                  },
                  {
                    text: `Interface with Scales to weigh packages.`,
                  },
                  {
                    text: `Use Barcode printers to print carrier approved shipping labels.`,
                  },
                  {
                    text: `Automatically calculate the cost and price of the container for COD and Insurance purposes.`,
                  },
                  {
                    text: `Compare the expected contents weight with the actual weight of the package thus preventing packing errors.`,
                  },
                  {
                    text: `Update the sales order with the appropriate freight charges.`,
                  },
                  {
                    text: `Track the cost of shipping by Shipping Agent and Service versus the amount charged to the customer.`,
                  },
                  {
                    text: `Track Packages and obtain delivery information from within Dynamics Business Central via the Internet.`,
                  },
                ]}
              />
            </p>
          ),
        },
        {
          title: "Compete more effectively with better insight into financials",
          text: `Microsoft Dynamics NAV for financial management can provide tools and insight to help your team make more informed decisions. These tools and insights can help your company set a competitive direction for your business. With Microsoft Dynamics financial solutions, you can transform financial and executive management of your company into a dependable, efficient process.`,
        },
        {
          title: "Realize your vision for growth",
          text: `Microsoft Dynamics solutions for financial management can help you promote the kind of business growth you want to bring about. Business and technical expertise from Microsoft and its many partners can assist you in gaining the best value from your technology as your requirements change.`,
        },
      ]}
    />
  )
}
